<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="160px"
    >
      <el-form-item label="部门" prop="departmentId">
        <el-select
          v-model="dataForm.departmentId"
          clearable
          filterable
          style="width: 100%"
          :disabled="dataForm.id ? true : false"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="事故分类" prop="type">
        <el-select
          v-model="dataForm.type"
          clearable
          filterable
          style="width: 100%"
          :disabled="dataForm.id ? true : false"
        >
          <el-option
            v-for="item in [
              { value: 0, label: '人员事故' },
              { value: 1, label: '车辆及其它财物事故' },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="责任归属" prop="ascription">
        <el-select
          v-model="dataForm.ascription"
          clearable
          filterable
          :disabled="dataForm.id ? true : false"
          style="width: 100%"
        >
          <el-option
            v-for="item in ['全责', '主责', '同责', '次责', '无责', '不定责']"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联的员工" prop="employeeId">
        <el-select
          v-model="dataForm.employeeId"
          clearable
          filterable
          @change="selectEmployee"
          style="width: 100%"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联的车辆" prop="carId">
        <el-select
          v-model="dataForm.carId"
          clearable
          filterable
          @change="selectCar"
          style="width: 100%"
        >
          <el-option
            v-for="item in carList"
            :key="item.id"
            :label="item.number"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="事故描述" prop="describeInfo">
        <el-input
          type="textarea"
          rows="4"
          v-model="dataForm.describeInfo"
          placeholder="事故描述"
        ></el-input>
      </el-form-item>
      <el-form-item label="事故类型" prop="eventType">
        <el-select
          v-model="dataForm.eventType"
          clearable
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in [
              '空防反恐',
              '飞行安全',
              '消防安全',
              '运行安全',
              '公共安全',
              '应急管理',
              '网络信息',
              '工程建设',
              '其它',
            ]"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="事故原因" prop="eventReason">
        <el-input
          type="textarea"
          rows="4"
          v-model="dataForm.eventReason"
          placeholder="事故原因"
        ></el-input>
      </el-form-item>
      <el-form-item label="事故时间" prop="eventDate">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="dataForm.eventDate"
          style="width: 100%"
          type="date"
          placeholder="选择事故时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="事故地址" prop="eventAddress">
        <el-input
          style="width: 100%"
          v-model="dataForm.eventAddress"
          placeholder="事故地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="事件相关方名称" prop="eventCompanyName">
        <el-input
          style="width: 100%"
          v-model="dataForm.eventCompanyName"
          placeholder="事件相关方名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="事件相关方联系方式" prop="eventCompanyPhone">
        <el-input
          style="width: 100%"
          v-model="dataForm.eventCompanyPhone"
          placeholder="事件相关方联系方式"
        ></el-input>
      </el-form-item>

      <el-form-item label="承保保险公司" prop="insureCompany">
        <el-input
          style="width: 100%"
          v-model="dataForm.insureCompany"
          placeholder="承保保险公司"
        ></el-input>
      </el-form-item>
      <el-form-item label="报案号" prop="caseNumber">
        <el-input
          v-model="dataForm.caseNumber"
          style="width: 100%"
          placeholder="报案号"
        ></el-input>
      </el-form-item>

      <el-form-item
        v-if="dataForm.status >= 2"
        label="质安部管理员意见"
        prop="zabglyOpinion"
      >
        <el-input
          disabled
          v-model="dataForm.zabglyOpinion"
          style="width: 100%"
          placeholder="质安部管理员意见"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="dataForm.status >= 4"
        label="质安部负责人意见"
        prop="xzbOpinion"
      >
        <el-input
          disabled
          v-model="dataForm.zabfzrOpinion"
          style="width: 100%"
          placeholder="质安部负责人意见"
        ></el-input>
      </el-form-item>

      <el-form-item label="损失费用明细单图片" prop="ssfyPic">
        <el-upload
          class="avatar-uploader"
          :action="$http.common.upload"
          list-type="picture-card"
          :on-success="ssfyPicSuccess"
          :headers="{ token: $store.state.token }"
          :on-preview="showDialogImage"
          :on-remove="ssfyPicRemove"
          :file-list="ssfyPicList"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogImageVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="保险赔付明细单图片" prop="bxpfPic">
        <el-upload
          class="avatar-uploader"
          :action="$http.common.upload"
          list-type="picture-card"
          :on-success="bxpfPicSuccess"
          :headers="{ token: $store.state.token }"
          :on-preview="showDialogImage"
          :on-remove="bxpfPicRemove"
          :file-list="bxpfPicList"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogImageVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="公司承担明细单图片" prop="gscdPic">
        <el-upload
          class="avatar-uploader"
          :action="$http.common.upload"
          list-type="picture-card"
          :on-success="gscdPicSuccess"
          :headers="{ token: $store.state.token }"
          :on-preview="showDialogImage"
          :on-remove="gscdPicRemove"
          :file-list="gscdPicList"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogImageVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="事件现场图片" prop="eventPic">
        <el-upload
          class="avatar-uploader"
          :action="$http.common.upload"
          list-type="picture-card"
          :on-success="eventPicSuccess"
          :headers="{ token: $store.state.token }"
          :on-preview="showDialogImage"
          :on-remove="eventPicRemove"
          :file-list="eventPicList"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogImageVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>

      <div style="margin-left: 64px; margin-top: 30px">
        <div>事故调查报告</div>
        <editor ref="sgdcbgEditor" v-model="dataForm.sgdcbgPic"></editor>
      </div>
      <div style="margin-left: 64px; margin-top: 30px">
        <div>责任人处理报告</div>
        <editor ref="zrrcldEditor" v-model="dataForm.zrrcldPic"></editor>
      </div>
      <div style="margin-left: 64px; margin-top: 30px">
        <div>整改措施情况说明</div>
        <editor ref="zgqksmEditor" v-model="dataForm.zgqksmPic"></editor>
      </div>
      <div style="margin-left: 64px; margin-top: 30px">
        <div>培训教育情况</div>
        <editor ref="pxjyEditor" v-model="dataForm.pxjyPic"></editor>
      </div>
    </el-form>
    <div>
      <p>日志记录</p>
      <el-input
        type="textarea"
        v-model="dataForm.log"
        disabled
        rows="6"
      ></el-input>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="flag == 1" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      ssfyPicList: [],
      bxpfPicList: [],
      gscdPicList: [],
      eventPicList: [],
      dialogImageVisible: false, //控制显示大图
      dialogImageUrl: "", // 大图的src
      visible: false,
      departmentList: [], // 部门列表
      employeeList: [], // 员工列表
      carList: [], //车辆列表
      dataForm: {
        id: 0,
        departmentId: "",
        type: "",
        employeeId: "",
        employeeName: "",
        carId: "",
        carNumber: "",
        describeInfo: "",
        eventType: "",
        eventReason: "",
        eventDate: "",
        eventAddress: "",
        eventCompanyName: "",
        eventCompanyPhone: "",
        ascription: "",
        insureCompany: "",
        caseNumber: "",
        xzbOpinion: "",
        zabglyOpinion: "",
        zabfzrOpinion: "",
        ssfyPic: "",
        bxpfPic: "",
        gscdPic: "",
        eventPic: "",
        sgdcbgPic: "",
        zrrcldPic: "",
        zgqksmPic: "",
        pxjyPic: "",
        status: "",
        log: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      flag: 1,
      dataRule: {
        departmentId: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        ascription: [
          { required: true, message: "责任归属不能为空", trigger: "blur" },
        ],
        type: [
          {
            required: true,
            message: "事故分类不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 选中车辆
    selectCar() {
      this.carList.forEach((item) => {
        if (item.id == this.dataForm.carId) {
          this.dataForm.carNumber = item.number;
        }
      });
    },
    // 选中员工
    selectEmployee() {
      this.employeeList.forEach((item) => {
        if (item.id == this.dataForm.employeeId) {
          this.dataForm.employeeName = item.name;
        }
      });
    },
    // 获取车辆列表
    getCarList() {
      this.$http.car.list({ page: 1, limit: 99999 }).then((res) => {
        if (!res.data) return;
        this.carList = res.data.list || [];
      });
    },
    // 获取员工列表
    getEleployeeList() {
      this.$http.employee.list({ page: 1, limit: 99999 }).then((res) => {
        this.employeeList = [];
        if (!res.data) return;
        if (res.data.list && res.data.list.length > 0) {
          res.data.list.forEach((item) => {
            this.employeeList.push({
              id: item.id,
              label: item.name + "(" + item.departmentName + ")",
              name: item.name,
              uid: item.uid,
            });
          });
        }
      });
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (!res.data) return;
        this.departmentList = res.data.list || [];
      });
    },
    init(id, flag) {
      if (flag == 0) {
        this.flag = 0;
      } else {
        this.flag = 1;
      }
      this.ssfyPicList = [];
      this.bxpfPicList = [];
      this.gscdPicList = [];
      this.eventPicList = [];

      this.getDepartmentList();
      this.getEleployeeList();
      this.getCarList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.accident.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;

              // 初始化富文本
              this.$refs.sgdcbgEditor.destroy();
              this.$refs.sgdcbgEditor.init(this.dataForm.sgdcbgPic);
              this.$refs.zrrcldEditor.destroy();
              this.$refs.zrrcldEditor.init(this.dataForm.zrrcldPic);
              this.$refs.zgqksmEditor.destroy();
              this.$refs.zgqksmEditor.init(this.dataForm.zgqksmPic);
              this.$refs.pxjyEditor.destroy();
              this.$refs.pxjyEditor.init(this.dataForm.pxjyPic);

              if (this.dataForm.ssfyPic) {
                let arr = this.dataForm.ssfyPic.split("_");
                arr.forEach((item) => {
                  this.ssfyPicList.push({
                    url: this.$http.common.pic + item,
                  });
                });
              }
              if (this.dataForm.bxpfPic) {
                let arr = this.dataForm.bxpfPic.split("_");
                arr.forEach((item) => {
                  this.bxpfPicList.push({
                    url: this.$http.common.pic + item,
                  });
                });
              }
              if (this.dataForm.gscdPic) {
                let arr = this.dataForm.gscdPic.split("_");
                arr.forEach((item) => {
                  this.gscdPicList.push({
                    url: this.$http.common.pic + item,
                  });
                });
              }
              if (this.dataForm.eventPic) {
                let arr = this.dataForm.eventPic.split("_");
                arr.forEach((item) => {
                  this.eventPicList.push({
                    url: this.$http.common.pic + item,
                  });
                });
              }
            }
          });
        } else {
          this.$refs.sgdcbgEditor.destroy();
          this.$refs.sgdcbgEditor.init(this.dataForm.sgdcbgPic);
          this.$refs.zrrcldEditor.destroy();
          this.$refs.zrrcldEditor.init(this.dataForm.zrrcldPic);
          this.$refs.zgqksmEditor.destroy();
          this.$refs.zgqksmEditor.init(this.dataForm.zgqksmPic);
          this.$refs.pxjyEditor.destroy();
          this.$refs.pxjyEditor.init(this.dataForm.pxjyPic);
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          // 整理图片格式
          this.dataForm.ssfyPic = "";
          if (this.ssfyPicList.length > 0) {
            let arr = [];
            this.ssfyPicList.forEach((item) => {
              arr.push(
                item.url.substring(
                  item.url.lastIndexOf("/") + 1,
                  item.url.length
                )
              );
            });
            this.dataForm.ssfyPic = arr.join("_");
          }

          this.dataForm.bxpfPic = "";
          if (this.bxpfPicList.length > 0) {
            let arr = [];
            this.bxpfPicList.forEach((item) => {
              arr.push(
                item.url.substring(
                  item.url.lastIndexOf("/") + 1,
                  item.url.length
                )
              );
            });
            this.dataForm.bxpfPic = arr.join("_");
          }
          this.dataForm.gscdPic = "";
          if (this.gscdPicList.length > 0) {
            let arr = [];
            this.gscdPicList.forEach((item) => {
              arr.push(
                item.url.substring(
                  item.url.lastIndexOf("/") + 1,
                  item.url.length
                )
              );
            });
            this.dataForm.gscdPic = arr.join("_");
          }
          this.dataForm.eventPic = "";
          if (this.eventPicList.length > 0) {
            let arr = [];
            this.eventPicList.forEach((item) => {
              arr.push(
                item.url.substring(
                  item.url.lastIndexOf("/") + 1,
                  item.url.length
                )
              );
            });
            this.dataForm.eventPic = arr.join("_");
          }

          if (this.dataForm.id) {
            // 修改
            this.$http.accident.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.accident.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
    // 展示大图
    showDialogImage(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    eventPicSuccess(response) {
      if (response.code === 0) {
        this.eventPicList.push({
          url: this.$http.common.pic + response.data,
        });
      }
    },
    eventPicRemove(file, fileList) {
      this.eventPicList = fileList;
    },
    gscdPicSuccess(response) {
      if (response.code === 0) {
        this.gscdPicList.push({
          url: this.$http.common.pic + response.data,
        });
      }
    },
    gscdPicRemove(file, fileList) {
      this.gscdPicList = fileList;
    },
    ssfyPicSuccess(response) {
      if (response.code === 0) {
        this.ssfyPicList.push({
          url: this.$http.common.pic + response.data,
        });
      }
    },
    ssfyPicRemove(file, fileList) {
      this.ssfyPicList = fileList;
    },
    bxpfPicSuccess(response) {
      if (response.code === 0) {
        this.bxpfPicList.push({
          url: this.$http.common.pic + response.data,
        });
      }
    },
    bxpfPicRemove(file, fileList) {
      this.bxpfPicList = fileList;
    },
  },
};
</script>
