<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          placeholder="按部门筛选"
          @visible-change="departmentSelect"
          @change="departmentSelectChange"
          name="departmen"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          placeholder="按员工筛选"
          @visible-change="employeeSelect"
          @change="employeeSelectChange"
        >
          <el-option
            v-for="item in employeeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="carId">
        <el-select
          v-model="dataForm.carId"
          clearable
          placeholder="按车辆筛选"
          @visible-change="carSelect"
        >
          <el-option
            v-for="item in carOptions"
            :key="item.id"
            :label="item.number"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        label="序号"
        width="70"
      >
      </el-table-column>
      <el-table-column
        prop="departmentName"
        header-align="center"
        align="center"
        label="部门"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="事故分类"
        width="80"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">人员事故</span>
          <span v-if="scope.row.type == 1">车辆及其它财物事故</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="employeeName"
        header-align="center"
        align="center"
        label="关联的员工"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="carNumber"
        header-align="center"
        align="center"
        label="关联的车辆"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="eventType"
        header-align="center"
        align="center"
        label="事故类型"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="ascription"
        header-align="center"
        align="center"
        label="责任归属"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="eventDate"
        header-align="center"
        align="center"
        label="事故时间"
        width="100"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.eventDate">{{
            scope.row.eventDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="eventAddress"
        header-align="center"
        align="center"
        label="事故地址"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        prop="describe"
        header-align="center"
        align="center"
        label="事故描述"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        prop="eventReason"
        header-align="center"
        align="center"
        label="事故原因"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">等待责任部门管理员补充情况</span>
          <span v-if="scope.row.status == 1"
            >部门管理员已补充情况，等待质安部管理员审核</span
          >
          <span v-if="scope.row.status == 2"
            >质安部管理员已通过审核，等待质安部负责人审核</span
          >
          <span v-if="scope.row.status == 3"
            >质安部管理员拒绝通过审核，请重新填写情况</span
          >
          <span v-if="scope.row.status == 4"
            >质安部负责人拒绝通过审核，请重新填写情况</span
          >
          <span v-if="scope.row.status == 5"
            >质安部负责人已通过审核，已归档保存</span
          >
          <span v-if="scope.row.status == 6"
            >四不放过，等待部门管理员上传相关材料</span
          >
          <span v-if="scope.row.status == 7"
            >部门管理员已上传四不放过材料，等待质安部负责人审核</span
          >
          <span v-if="scope.row.status == 8"
            >质安部负责人拒绝通过审核，请重新上传相关材料</span
          >
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="250"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id, 0)"
            >查看</el-button
          >
          <el-button
            v-if="
              scope.row.status == 0 ||
              scope.row.status == 3 ||
              scope.row.status == 4
            "
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            >填写事故情况</el-button
          >
          <el-button
            v-if="scope.row.status == 1"
            type="primary"
            size="mini"
            @click="zabglyspHandle(scope.row.id)"
            >质安部管理员审批</el-button
          >
          <el-button
            v-if="scope.row.status == 2"
            type="primary"
            size="mini"
            @click="zabfzrspHandle(scope.row.id)"
            >质安部负责人审批</el-button
          >
          <el-button
            v-if="scope.row.status == 6 || scope.row.status == 8"
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            >上传材料</el-button
          >
          <el-button
            v-if="scope.row.status == 7"
            type="primary"
            size="mini"
            @click="sbfgHandle(scope.row.id)"
            >四不放过审批</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>

    <el-dialog
      title="质安部管理员审核"
      :visible.sync="zabglyVisible"
      width="30%"
    >
      <el-input v-model="zabgly" placeholder="请输入您的审核意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            zabglyVisible = false;
            zabglyspSubmit(1);
          "
          >拒绝</el-button
        >
        <el-button
          type="primary"
          @click="
            zabglyVisible = false;
            zabglyspSubmit(0);
          "
          >通过</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="质安部负责人审核"
      :visible.sync="zabfzrVisible"
      width="30%"
    >
      <el-input v-model="zabfzr" placeholder="请输入您的审核意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            zabfzrVisible = false;
            zabfzrspSubmit(1);
          "
          >拒绝</el-button
        >
        <el-button
          type="primary"
          @click="
            zabfzrVisible = false;
            zabfzrspSubmit(2);
          "
          >通过，启动四不放过程序</el-button
        >

        <el-button
          type="primary"
          @click="
            zabfzrVisible = false;
            zabfzrspSubmit(0);
          "
          >通过，归档保存</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="四不放过审核" :visible.sync="sbfgVisible" width="30%">
      <el-input v-model="sbfg" placeholder="请输入您的审核意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            sbfgVisible = false;
            sbfgSubmit(1);
          "
          >拒绝</el-button
        >
        <el-button
          type="primary"
          @click="
            sbfgVisible = false;
            sbfgSubmit(0);
          "
          >通过，归档保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddOrUpdate from "./accident-add-or-update";

export default {
  data() {
    return {
      id: "",
      zabglyVisible: false,
      zabfzrVisible: false,
      sbfgVisible: false,
      dataForm: {
        keyword: "",
        did: "",
        eid: "",
        carId: "",
      },

      departmentOptions: [], //部门列表选项
      employeeOptions: [], //员工列表选项
      carOptions: [], //员工列表选项

      zabgly: "",
      zabfzr: "",
      sbfg: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 部门选择(打开下拉框)
    departmentSelect(e) {
      if (e) {
        this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
          this.departmentOptions = res?.data?.list;
        });
      }
    },
    // 部门选择(选择发生变化)
    departmentSelectChange(e) {
      this.dataForm.eid = "";
      this.employeeOptions = [];
      this.dataForm.carId = "";
      this.carOptions = [];
    },

    // 员工选择(打开下拉框)
    employeeSelect(e) {
      if (e) {
        this.$http.employee
          .list({
            did: this.dataForm.did,
            tid: this.dataForm.tid,
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.employeeOptions = res?.data?.list;
          });
      }
    },
    // 员工选择(选择发生变化)
    employeeSelectChange(e) {
      this.dataForm.carId = "";
      this.carOptions = [];
    },
    // 车辆选择(打开下拉框)
    carSelect(e) {
      if (e) {
        this.$http.car
          .list({
            did: this.dataForm.did,
            eid: this.dataForm.eid,
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.carOptions = res?.data?.list;
          });
      }
    },

    // 四不放过审批
    sbfgSubmit(flag) {
      if (flag == 1) {
        this.$http.accident
          .sbfgApproval({ id: this.id, flag, content: this.sbfg })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      } else {
        this.$http.accident
          .sbfgApproval({ id: this.id, flag, content: this.sbfg })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      }
    },
    // 显示四不放过审批
    sbfgHandle(id) {
      this.id = id;
      this.sbfgVisible = true;
    },
    // 质安部负责人审批 0：同意  1：拒绝
    zabfzrspSubmit(flag) {
      if (flag == 1) {
        this.$http.accident
          .zabfzrApproval({ id: this.id, flag, content: this.zabfzr })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      } else {
        this.$http.accident
          .zabfzrApproval({ id: this.id, flag, content: this.zabfzr })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      }
    },
    // 显示质安部负责人审批
    zabfzrspHandle(id) {
      this.id = id;
      this.zabfzrVisible = true;
    },
    // 质安部管理员审批 0：同意  1：拒绝
    zabglyspSubmit(flag) {
      if (flag == 1) {
        this.$http.accident
          .zabglyApproval({ id: this.id, flag: 1, content: this.zabgly })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      } else {
        this.$http.accident
          .zabglyApproval({ id: this.id, flag: 0, content: this.zabgly })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message.success(data.msg);
              this.getDataList();
            }
          });
      }
    },
    // 显示质安部管理员审批
    zabglyspHandle(id) {
      this.id = id;
      this.zabglyVisible = true;
    },
    // 归档保存
    archiveHandle(id) {
      this.$http.accident.archive({ id }).then((data) => {
        if (data && data.code === 0) {
          this.$message.success(data.msg);
          this.getDataList();
        }
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.accident.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id, flag) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, flag);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.accident
          .delete({
            id,
          })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message({
                message: data.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
            }
          });
      });
    },
  },
};
</script>
